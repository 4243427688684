import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useLocation, navigate } from "@reach/router"
import useScroll from "../hooks/useScroll"

const Header = ({ siteTitle }) => {
  const location = useLocation()
  const { scrollDirection, scrollY } = useScroll()

  const [isActive, setIsActive] = useState(false)
  const [toHash, setToHash] = useState(false)

  useEffect(() => {
    if (location.hash === "#work") {
      setIsActive(true)
    } else {
      setIsActive(false)
    }

    if (location.pathname.split("/")[1] === "work") {
      setIsActive(true)
    }
  }, [location.hash, location.pathname])

  useEffect(() => {
    if (location.pathname === "/") {
      const boundingClientRectTop = document
        .getElementById("work")
        .getBoundingClientRect().top
      if (boundingClientRectTop < 1 && scrollDirection === "up" && !toHash) {
        setToHash(true)
        navigate("/#work", { replace: false })
      }
      if (boundingClientRectTop > 2 && scrollDirection === "down" && toHash) {
        setToHash(false)
        navigate("/", { replace: false })
      }
    }
  }, [scrollY, scrollDirection, location.pathname, toHash])

  return (
    <header>
      <div>
        <nav className="menu">
          <div className="hover-animation">
            <Link to="/">
              <img className="logo" src={"../../brille-icon.svg"} alt="logo" />
              <img
                className="logo_hover"
                src={"../../brille-icon_Punktaugen.svg"}
                alt="logo_hover"
              />
            </Link>
          </div>
          <ul className="nav_links">
            <li>
              <Link
                to="/#work"
                className={`chunky${isActive ? " active" : ""}`}
              >
                Work
              </Link>
            </li>
            <li>
              <Link to="/about" className="chunky" activeClassName="active">
                About
              </Link>
            </li>
            <li>
              <Link
                to="/play"
                className="chunky"
                activeClassName="active"
                partiallyActive={true}
              >
                Play
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
