/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import Header from "./header"
import "./layout.css"

const CustomToast = ({ closeToast }) => (
  <div style={{ display: "flex", justifyContent: "space-between" }}>
    <p style={{ margin: 12 }}>E-mail copied to clipboard!</p>
    <button
      onClick={closeToast}
      style={{
        border: "none",
        backgroundColor: "transparent",
        color: "#4404f7",
        fontFamily: "Jost",
        fontSize: "1rem",
        margin: "0 0.7rem",
        cursor: "pointer",
      }}
    >
      GOT IT
    </button>
  </div>
)

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const copyToClip = e => {
    e.preventDefault()
    navigator.clipboard.writeText("hello@anjaschneck.com").then(
      () => {
        toast(<CustomToast />, {
          position: "bottom-left",
          closeButton: false,
          className: "toast",
          progressClassName: "toast-progress",
        })
      },
      () => {
        toast.error("could not copy to clipboard", {
          position: "bottom-left",
        })
      }
    )
  }

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />

      <main>{children}</main>
      <footer>
        <div className="main-container">
          <h2>Say hello.</h2>
          <div className="footer-details">
            <a
              className="footer-link"
              href="mailto:anja@dos-a.com"
              onClick={copyToClip}
            >
              E-Mail
            </a>
            <span className="foot-separator">|</span>
            <a
              className="footer-link"
              href="https://twitter.com/AnjaSchneck"
              target="blank"
            >
              Twitter
            </a>
            <span className="foot-separator">|</span>
            <a
              className="footer-link"
              href="https://dribbble.com/AnjaSchneck"
              target="blank"
            >
              Dribbble
            </a>
            <span className="foot-separator">|</span>
            <a
              className="footer-link"
              href="https://www.linkedin.com/in/anja-schneck-7324b629/"
              target="blank"
            >
              Linkedin
            </a>
          </div>
        </div>
      </footer>
      <ToastContainer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
